// icon:flower | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export default function Deceased(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 14"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
        <path
              d="M9.1629125 3.0625H7.43752187v-1.75c0-.72488281-.58789062-1.3125-1.3125-1.3125h-1.75c-.72460937 0-1.3125.58761719-1.3125 1.3125v1.75H1.2879125C.58763906 3.0625 0 3.65039062 0 4.375v1.75c0 .72488281.58761719 1.3125 1.28789062 1.3125h1.77463125v5.25c0 .7246094.58789063 1.3125 1.3125 1.3125h1.75c.72488282 0 1.3125-.5876172 1.3125-1.3125v-5.25H9.1629125c.72488281 0 1.3125-.58761719 1.3125-1.3125v-1.75c0-.72460938-.56328125-1.3125-1.3125-1.3125Zm0 3.0625H6.12502187v6.5625h-1.75V6.125H1.2879125v-1.75h3.08710937V1.3125h1.75V4.375H9.1629125v1.75Z"/>
    </svg>
  );
}
